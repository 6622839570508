import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ServerDummies';
  private showSmallNav = false;
  private smallNavClass = '';

  scrollPage(to){
    window.scrollTo(500, to);
  }
  smallNavClicked(){
    this.toggleSmallScreenNav();
    window.scrollTo(500,0);
  }

  toggleSmallScreenNav(){
   if(!this.showSmallNav){
      //this.smallNavClass = 'animated slideInRight faster expand'
      this.smallNavClass = ' expand'
   }else{
    //this.smallNavClass = 'animated slideOutRight faster  collapse'
    this.smallNavClass = ' collapse'
   }

   this.showSmallNav = !this.showSmallNav;
  }
}
