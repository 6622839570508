import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GenerateJsonService {
  private generatedData: any;
  private generatedDataUpdated = new Subject();
  private generatedCount = new Subject();

  constructor(private _http: HttpClient) {}

  url = "https://www.serverdummies.com";

  generateAPI(params) {
    let options;
    if (params) {
      options = {
        params: new HttpParams().set("structure", params.replace(/\n/g, "")),
      };
    }
    return options.params;
  }

  generateJson(params) {
    let options;
    if (params) {
      options = {
        params: new HttpParams().set("structure", params.replace(/\n/g, "")),
      };
    }

    //console.log("params to be sent : ", params.replace(/\n/g, ''))

    return this._http.get<any>(this.url + "/api/generate", options).subscribe(
      (res) => {
        this.generatedData = res;
        this.generatedDataUpdated.next(this.generatedData);
      },
      (error) => {
        throw error;
      }
    );
  }

  getGeneratedJson() {
    return this.generatedDataUpdated.asObservable();
  }

  generateCount() {
    //console.log("params to be sent : ", params.replace(/\n/g, ''))
    return this._http.get<any>(this.url + "/read").subscribe((res) => {
      this.generatedCount.next(res);
    });
  }

  getCount() {
    return this.generatedCount.asObservable();
  }
}
