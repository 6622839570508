import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [ 
  { path: 'SD-Home', 
     loadChildren: () => import('./home-page/home-page.module').then(m =>     
     m.HomePageModule) 
  },
  { path: 'SD-JSONEngine', 
     loadChildren: () => import('./json-server-page/json-server-page.module').then(m =>     
     m.JsonServerPageModule) 
  },
  { path: 'SD-Grid', 
     loadChildren: () => import('./easy-grid-page/easy-grid-page.module').then(m =>     
     m.EasyGridPageModule) 
  },
  { path: 'SD-Angular-Components', 
     loadChildren: () => import('./js-components-main-page/components-page.module').then(m =>     
     m.ComponentsPageModule) 
  },
  { path: 'SD-CSS-Libraries', 
     loadChildren: () => import('./css-library-main-page/css-library-page.module').then(m =>     
     m.CssLibraryPageModule) 
  },
  { path: 'SD-Meet-The-Creator', 
  loadChildren: () => import('./meet-the-creator-page/meet-the-creator.module').then(m =>     
  m.MeetTheCreatorModule) 
},
  {
    path: '',
    redirectTo: 'SD-Home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
